import React, { useState } from 'react'
import Icon from '../atoms/Icon'
import Button from '../atoms/Button';

const Notification = () => {
  const [activeNotifications, setActiveNotifications] = useState(false);
  const [activeNotificationsContent, setActiveNotificationsContent] = useState(false);


  const handleActiveNotification = () => {
    setActiveNotifications(!activeNotifications)
  }
  const handleOnmouseActiveNotification = () => {
    setActiveNotifications(!activeNotifications)
  }


  return (
    <div className='Notification active-notification'>
      <div
        onClick={handleActiveNotification}
        className="icon-notification">
        <Icon name='notification' />
      </div>

      {
        (activeNotifications) &&
        <div onMouseLeave={handleOnmouseActiveNotification} className="content-notification active">
          <div onClick={handleOnmouseActiveNotification} className="close"><Icon name='close' /> </div>
          {
            activeNotificationsContent &&
            <div className="back-button" onClick={() => setActiveNotificationsContent(!activeNotificationsContent)}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M6.00016 9.33333L3.3335 6.66667L6.00016 4" stroke="#0048DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.3335 6.66699H10.6668C11.3741 6.66699 12.0524 6.94794 12.5524 7.44804C13.0525 7.94814 13.3335 8.62641 13.3335 9.33366C13.3335 10.0409 13.0525 10.7192 12.5524 11.2193C12.0524 11.7194 11.3741 12.0003 10.6668 12.0003H10.0002" stroke="#0048DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div>
                <span>Regresar</span>
              </div>
            </div>
          }

          {
            (activeNotificationsContent)
              ?
              <div className="info-notification">
                <div className='img_notification'>
                  <img src="/assets/images/img_notification.png" alt="" />
                </div>
                <div className='title'>
                  <h4>Título del mensaje</h4>
                </div>
                <div className="message-notification">
                  <p>
                    Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor {'(N. del T. persona que se dedica a la imprenta)'} desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen.
                  </p>
                </div>
                <div className="btn-actions">
                  <Button
                    type='primary'
                    text='Aceptar y cerrar'
                    status='active'
                    onClick={setActiveNotificationsContent}
                  />
                </div>
              </div>
              :
              <>

                <ul>
                  <li onClick={() => setActiveNotificationsContent(!activeNotificationsContent)} className="list-notification esfera">
                    <h3 className='new-notification'>Estás muy cerca de lograrlo y ganar un premio espectacula... <span className='icon-list-notification'><Icon name='arrow_red' /></span></h3>
                  </li>
                  <li onClick={() => setActiveNotificationsContent(!activeNotificationsContent)} className="list-notification">
                    <h3>Estás muy cerca de lograrlo y ganar un premio espectacula... <span className='icon-list-notification'><Icon name='arrow_blue' /></span></h3>
                  </li>
                  <li onClick={() => setActiveNotificationsContent(!activeNotificationsContent)} className="list-notification">
                    <h3>Estás muy cerca de lograrlo y ganar un premio espectacula... <span className='icon-list-notification'><Icon name='arrow_blue' /></span></h3>
                  </li>
                  <li onClick={() => setActiveNotificationsContent(!activeNotificationsContent)} className="list-notification">
                    <h3>Estás muy cerca de lograrlo y ganar un premio espectacula... <span className='icon-list-notification'><Icon name='arrow_blue' /></span></h3>
                  </li>
                  <li onClick={() => setActiveNotificationsContent(!activeNotificationsContent)} className="list-notification">
                    <h3>Estás muy cerca de lograrlo y ganar un premio espectacula... <span className='icon-list-notification'><Icon name='arrow_blue' /></span></h3>
                  </li>
                </ul>
                <div className="btn-actions">
                  <Button
                    type='primary'
                    text='Cerrar'
                    status='active'
                    onClick={setActiveNotifications}
                  />
                </div>
              </>

          }


        </div>


      }
    </div>
  )
}

export default Notification
