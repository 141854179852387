import React, { useEffect, useState } from 'react'

const LoadingRedemption = ({text}) => {
    const [loadingProgress, setLoadingProgress] = useState(0);
    useEffect(() => {        
        let initialInterval = null;
    
        if (loadingProgress <= 90) {
            initialInterval = setInterval(() => {
                setLoadingProgress(prevProgress => prevProgress + 10);
            }, 350);
        }
    
        return () => {
            clearInterval(initialInterval);
        };
    }, [loadingProgress]);
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
      }, [])
    return (
        <div className='LoadingRedemption'>
            {text &&
            <h4>Pronto podrás disfrutar tu premio...</h4>
            
            }
            <p className='lbl-progress'>{loadingProgress}%</p>
            <div className="Progress">
                <div className="barProgress"></div>
            </div>
        </div>
    )
}

export default LoadingRedemption
