import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import PageHeaders from '../atoms/PageHeaders'
import SliderRotator from '../organisms/SliderRotator'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Button from '../atoms/Button'
import DynamicBanner from '../molecules/DynamicBanner'
import { getCardsSlides, getTextTypeCard } from '../../utils/functions'
import Preloader from '../atoms/Preloader'
import Roulette from '../atoms/Roulette'

function Mechanic() {
  const {context} = useContext(StateController)
  const [goalRoulette, setGoalRoulette] = useState(false)
  const [progressRoulette, setProgressRoulette] = useState(false)
  const [rouleteAwards, setRouleteAwards] = useState(false)
  const [rouletteWinner, setRouletteWinner] = useState(false)
  const [showRoulette, setShowRoulette] = useState(false)
  const [completeAwards, setCompleteAwards] = useState(false)
  const [showPush70, setShowPush70] = useState(false)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (context) {
      if (context.user) {
        setGoalRoulette(
          context.user[`goal_extra_${context.block}`]
        )
      }

      if (context.tracing) {
        setProgressRoulette(
          context.tracing[`amount_extra_${context.block}`]
        )
        if (context.tracing[`winner_extra_${context.block}`]) {
          setRouletteWinner(true)
        }

        if (context.tracing.extra2 === "1") {
          setShowPush70(true)
        }
      }

      if (context.awards && context.awards[`extra_${context.block}`]) {
        setCompleteAwards(context.awards[`extra_${context.block}`].awards)

        let pre = []
        for (let i = 0; i < context.awards[`extra_${context.block}`].awards.length; i++) {
          const awrd = context.awards[`extra_${context.block}`].awards[i]

          if (
            awrd.name == 'Totto' ||  
            awrd.name == 'Velez' || 
            awrd.name == 'Alkosto' || 
            awrd.name == 'Jumbo' || 
            awrd.name == 'Éxito' || 
            awrd.name == 'Crepes & Wafles' || 
            awrd.name == 'Frisby' || 
            awrd.name == 'Pepeganga'
          ) {
            pre.push({
              award_id: awrd.id,
              award_name: awrd.name,
              award_image: `rul-${awrd.image}`,
              award_value: awrd.price
            })
          }
          
        }
        setRouleteAwards(pre)
      }
    }
  }, [context])

  const openModalRoulette = () => {
    setShowRoulette(true)
  }

  return (
    <>
      {
        context 
        ?
          <div className='mc-page mechanic slow_ani'>

          {getTextTypeCard(context) &&
            <PageHeaders
            title="<span>¿Cómo ganar?</span>"
            text={`¡Con estos sencillos pasos puedes ganar con las compras de tus Tarjetas ${getTextTypeCard(context)} Mastercard AV Villas!`}
            />
          }
           

        <div className='mc-section-login-slider'>
          <SliderRotator
            mobileItemsToShow={1}
            desktopItemsToShow={3}
            style={1}
            ext={true}
            ignoreOnDesk={true}
            arrows={false}
            list={getCardsSlides(context)}
          />
        </div>

        <Container>
          <PrimaryBanner
            className="banner-divider"
            image={getTextTypeCard(context) === 'Débito' ? 'cards_debito.png' : getTextTypeCard(context) === 'Crédito' ? 'cards_credito.png': getTextTypeCard(context) === 'Débito y Crédito' ? 'cards_all_segment.png':'cards_all_segment.png'}
          />
        </Container>


        <Container>
          {context &&
            <DynamicBanner context={context} push70={showPush70} />
          }
        </Container>

        {/* <PageHeaders
          title="<span>Gira nuestra ruleta</span><br /> y gana más"
          text="Los retos sorpresa pueden aparecer en cualquier momento. <b>Supéralos, gira la ruleta y gana.</b>"
        /> */}

        {/* <Container>
          <PrimaryBanner
            image="ruleta.png"
          />
          <div className='mc-medium-divider' />
          <Button
            type="primary"
            text="Ver mi progreso"
            status="active"
            link="/progreso"
          />
        </Container> */}
          </div>
        :
          <Preloader type={'fullscreen'}  />
      }
    </>
  )
}
export default Mechanic