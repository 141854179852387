import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import {
  useLocation 
} from 'react-router-dom'

import { StateController } from './../../App'

import VerticalCenter from '../VerticalCenter/VerticalCenter'
import Container from '../Container/Container'
import Icon from '../../components/atoms/Icon'
import Profile from '../../components/atoms/Profile'
import { sendDataLayer } from '../../utils/functions'
import Notification from '../../components/molecules/Notification'

function Header({
  page,
  headerType,
  logo,
  items,
  submenu,
  loggedIn,
  logOutAction,
  setShowConfirm,
  rouletteWinner,
  hasRouletteAward,
  alreadyRunner
}) {
  const { context } = useContext(StateController)
  const location = useLocation()
  const [showOthers, setShowOthers] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showToast, setShowToast] = useState(true)


  const isSubLevel = (children) => {
    const page = location.pathname.replace('/','')
    const data = children.find((item) => item.page === page)

    return data ? true : false
  }

  const togglerMenu = (e) => {
    setShowOthers(!showOthers)
    sendDataLayer({datalayer: {event: "menu",text: e.target.textContent,link: e.target.textContent === 'Términos y condiciones' ? '/terminos-y-condiciones' : e.target.textContent === 'Preguntas frecuentes' ? '/preguntas-frecuentes' : ''},repeat: true})

  }

  const toogleSumMenu = (e) => {
    setShowMenu(!showMenu)
    sendDataLayer({datalayer: {event: "menu",text: e.target.textContent,link: window.location.href},repeat: true})

  }

  const closeSumMenu = () => {
    setShowMenu(false)
  }

  const addDataLayer = (e,page) => {
    sendDataLayer({datalayer: {event: "menu",text: e.target.textContent,link: `/${page}` },repeat: true})
    setShowConfirm(false)

  }

  useEffect(() => {
    const child = items.find((item) => item.sublevel)

    if (child) {
      setHasChildren(child)
    }
  }, [items])

  useEffect(() => {

    window.addEventListener('click', ({ target }) => {
      const window = target.closest('.mc-header-container__nav--extra')
      const button = target.closest('.mc-header-container__nav--button')

      if (window === null && button === null) setShowOthers(false)
    })
  }, [])

  const closeSession = () => {
    logOutAction()
    setShowOthers(false)
    sendDataLayer({datalayer: {event: "menu",text: "Salir",link: '/'},repeat: true})
  }

  const openGlobalRoulette = () => {
    console.log("Open global rulete?")
    document.querySelector('#girar-ruleta')?.click()
  }

  const closeTooltip = () => {
    setShowToast(false)
  }

  return (
    <div 
      className={`
      mc-header-container mc-header-container-type-${headerType} 
      mc-header-container-page-${page !== '' ? page ? page : 'login' : 'login' } 
      ${showMenu ? 'show-menu' : ''}`
    }>
      <div className='mc-header-container__menu'>
        <Container fullHeight={true}>
            
          <a className="mc-header-container__logo" href="/">
            <VerticalCenter>
              <img src={`/assets/images/${logo}`} alt="Logo Banco AV Villas" />
            </VerticalCenter>
          </a>

          {showToast && rouletteWinner && (!alreadyRunner || !hasRouletteAward) && 
            <div className={`mc-header-container__nav--push-roulette desktop ${localStorage.getItem('rouletteSaved') ? 'hasRouletteAward' : ''}`}>
              <div onClick={openGlobalRoulette}></div>
              <div onClick={closeTooltip}></div>
              <img src='/assets/images/push-ruleta.svg' />
            </div>
          }

          {headerType === 1 && loggedIn &&
            <div className="mc-header-container__mobile--close" onClick={closeSession}>
              <VerticalCenter>
                <Icon name="close_session" />
              </VerticalCenter>
            </div>
          }

          {headerType === 4 &&
            <div className="mc-header-container__mobile--submenu" onClick={toogleSumMenu}>
              <VerticalCenter>
                <Icon name="menu" />
              </VerticalCenter>
            </div>
          }

          <nav className="mc-header-container__nav">

            {showToast && rouletteWinner && (!alreadyRunner || !hasRouletteAward) && 
              <div className={`mc-header-container__nav--push-roulette mobile ${localStorage.getItem('rouletteSaved') ? 'hasRouletteAward' : ''}`}>
                <div onClick={openGlobalRoulette}></div>
                <div onClick={closeTooltip}></div>
                <img src='/assets/images/push-ruleta.svg' />
              </div>
            }
            

            {headerType === 4 &&
              <div
                className="mc-header-container__mobile--overlay" 
                onClick={closeSumMenu}
              >
              </div>
            }
            {headerType === 4 &&
              <div className="mc-header-container__nav--close" onClick={closeSumMenu}>
                <Icon name="close" />
              </div>
            }

            {loggedIn
              ? <ul>
                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      return true
                    } else {
                      return false
                    }
                  }).map((item, index) => {
                    return (
                      item.sublevel
                        ? <li 
                            className={`mc-header-container__nav--item slow_ani ${item.page} ${ ((showOthers) || (isSubLevel(item.children))) ? 'mc-header-container__nav--item-active' : ''}`}
                            onClick={togglerMenu}
                            key={index}
                          >
                            <div className="mc-header-container__nav--button">
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title} 
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='hiden'>
                                    <path d="M8.5 10L4 5" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.5 10L13 5" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                </span>
                              </VerticalCenter>
                            </div>
                          </li>
                        : <li 
                            className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`} 
                            onClick={(e)=> addDataLayer(e, item.page)}
                            key={index}
                          >
                            <Link to={`/${item.page}`}>
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title}
                                </span>
                              </VerticalCenter>
                            </Link>
                          </li>
                    )
                  })}
                </ul>
              : <ul>

                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      return false
                    } else {
                      return true
                    }
                  }).map((item, index) => {
                    return (
                      <li 
                        className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`} 
                        onClick={(e)=> addDataLayer(e, item.page)}
                        key={index}
                      >
                        <Link to={`/${item.page}`}>
                          <VerticalCenter>
                            <span>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              {item.title}
                            </span>
                          </VerticalCenter>
                        </Link>
                      </li>
                    )
                  })}

                  <li className={`mc-header-container__nav--item-active slow_ani`}>
                    <Link to="/" className='mc-header-link'>
                      <span>Ingresar</span>
                    </Link>
                  </li>
                </ul>
            }

            {hasChildren &&
              <div className={`mc-header-container__nav--extra slow_ani ${showOthers ? 'opened' : ''}`}>
                <div className="mc-header-container__nav--close" onClick={closeSession}>
                  Salir
                </div>
                {hasChildren.children.map((item, index) => {
                  return (
                    <Link to={`/${item.page}`} key={index} onClick={togglerMenu}>
                      <span>
                        {item.icon &&
                          <Icon name={item.icon} />
                        }
                        {item.title}
                      </span>
                    </Link>
                  )
                })}
              </div>
            }
          </nav>

          {
            loggedIn &&
            <div className='item-notification'>
            <Notification />
          </div>
          }
        </Container>
      </div>

      {submenu &&
        <div className={`mc-header-container__submenu ${!loggedIn ? 'not-login' : ''}`}>
          <Container fullHeight={true}>
            <VerticalCenter>
              {submenu.type === 'labels' &&
                <>
                  {submenu.icon && <Icon name={submenu.icon} />}
                  <div dangerouslySetInnerHTML={{ __html: submenu.content }} />
                </>
              }
              {submenu.type === 'nickname' && loggedIn && context?.user?.nickname &&
                <Profile
                  nickname={context.user.nickname}
                  defaultLetter={submenu.default}
                  context={context}
                />
              }
            </VerticalCenter>
          </Container>
        </div>
      }
    </div>
  )
}

export default Header