import React, { useEffect, useState, useContext } from 'react'
import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import Preloader from '../atoms/Preloader'
import BackButton from '../atoms/BackButton'

function Terms() {
  const { staticContent } = useContext(StateController)
  const [termsHTML, setTermsHTML] = useState(false)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (staticContent && staticContent.terms && staticContent.terms[0].content) {
      setTermsHTML(staticContent.terms[0].content)
    }
  }, [staticContent])
  
  return (
    <div className='mc-page terms slow_ani'>

      <Container className="mc-page-container">

        <BackButton />
        <a className='extra-link' href='/preguntas-frecuentes'>Preguntas frecuentes</a>

        <p className="head-terms-title">Conoce todos los detalles de nuestra campaña y sigue <strong>conectando tu manera de pagar y ganar.</strong></p>

        <div className="head-terms-box">
          <h1>Términos y condiciones campaña “Conecta con tus compras”</h1>
          <p>Banco AV Villas</p>
          <a href="https://ganacontustarjetasmastercard.bancoavvillas.com.co">https://ganacontustarjetasmastercard.bancoavvillas.com.co</a>
        </div>

        {termsHTML
          ? <div className='mc-static-content'>
              <div dangerouslySetInnerHTML={{ __html: termsHTML }} />
            </div>
          : <Preloader
              type="section"
              invert
            />
        }
      </Container>

    </div>
  )
}
export default Terms