import React, { useEffect, useState } from 'react'

import { getLevelName, getPrice, getLevel, getLevelPercent, sendDataLayer, getLevelPercentByOne } from '../../utils/functions'
import Levels from '../molecules/Levels'
import { Link } from 'react-router-dom'

function Profile({
  nickname,
  defaultLetter,
  context
}) {
  const [letters, setLetters] = useState('')
  const [progress1, setProgress1] = useState(0)
  const [progress2, setProgress2] = useState(0)

  useEffect(() => {
    
    if (nickname) {
      const names = nickname.split(' ')

      if (names[0][0]) {
        setLetters(
          `${names[0][0]}${names[1] ? names[1][0] : ''}`
        )
      } else {
        setLetters(
          defaultLetter
        )
      }
      
    }
  }, [nickname])

  useEffect(() => {
    
    if (context) {
      if (context.tracing) {
        setProgress1(
          Number(getLevelPercentByOne(context, 1))
        )
        setProgress2(
          Number(getLevelPercentByOne(context, 2))
        )
      }
      
    }
  }, [context])

  const dataLayerVerMas = ()=>{    
    sendDataLayer({datalayer:{ event: "menu", text: "Ver más", link: "/progreso" }, repeat: true})
  }
  
  return (
    <div className={`mc-profile-card v1 current-level-${getLevel(context)}`}>
      <div className={`mc-profile-avatar`}>
        <div className={`mc-profile-avatar__letters slow_ani`}>{letters}</div>
      </div>
      <div className={`mc-profile-name slow_ani`}>
        {nickname} <br />
        <span>Nivel: {getLevelName(getLevel(context))}</span>
      </div>
      <div className={`mc-profile-level slow_ani`}>
        <Levels
          levels={
            [
              {
                image: 'brujula.png',
                title: getLevelName(1),
                subtitle: 'Meta',
                amount: getPrice(context.user[`goal_${context.block}_level_1`]),
                active: getLevel(context) === 1 ? true : false,
                percent: Number(progress1)
              },
              {
                image: 'globo.png',
                title: getLevelName(2),
                subtitle: 'Meta',
                amount: getPrice(context.user[`goal_${context.block}_level_2`]),
                active: getLevel(context) === 2 ? true : false,
                percent: Number(progress2)
              }
            ]
          }
          type={'profile'}
          level={getLevel(context)}
          percent={Number(getLevelPercent(context, 1).replace('%','')) + Number(getLevelPercent(context, 2).replace('%',''))}
        />

        <span className='mc-profile-level__more'>
          <Link onClick={dataLayerVerMas} to="/progreso">Ver más</Link>
        </span>
      </div>
    </div>
  )
}

export default Profile