import React from 'react'

function PrimaryBanner({
  image,
  className
}) {

  return (
    <div className={`mc-banner-container ${className}`}>
      <img src={`/assets/images/${image}`} alt="Banner principal" />
    </div>
  )
}

export default PrimaryBanner