import React, { useEffect } from 'react'
import Input from '../atoms/Input'
import { gtCities, gtDepartments, gtSities } from '../../utils/helpers'

const FormQuantumData = ({ 
	location, 
	token, 
	brand_id,
	quantumName,
	setQuantumName,
	quantumDoc,
	setQuantumDoc,
	department,
	city,
	site,
	setDepartment,
	setCity,
	setSite,

}) => {
	
	const handleChangeName = async (e) => {
		setQuantumName(e.target.value)

	}
	const handleChangeDoc = async (e) => {
		const numbers = /^\d+$/
		if ((numbers.test(e.target.value) || e.target.value === '') && e.target.value.length < 13) {
			setQuantumDoc(e.target.value)
		}
	}

	const handleChangeDepartment = async (e) => {		
		setDepartment({ ...department, selected: e.target.value })
	}
	const handleChangeCity = async (e) => {
		setCity({ ...city, selected: e.target.value })
	}
	const handleChangeSite = async (e) => {
		setSite({ ...site, selected: e.target.value })
	}

	useEffect(() => {
		if (brand_id && location === 2) {
			gtDepartments(token, brand_id).then((deps) => {				
				if (deps?.data?.response && deps.data.response.error === false) {

					setDepartment({
						list: deps.data.response.message,
						selected: null
					})
				}

			}).catch(error => {
				console.log("ERROR DEPARTMENTS:", error)
			})
		}
	}, [location])
	
	useEffect(() => {
		if (department.selected && location === 2) {
			gtCities(token, brand_id, Number(department?.selected)).then((cities) => {				
				if (cities?.data?.response && cities.data.response.error === false) {

					setCity({
						list: cities.data.response.message,
						selected: null
					})
				}

			}).catch(error => {
				console.log("ERROR CIUDADES:", error)
			})
		}
	}, [department?.selected])

	useEffect(() => {
		if (city.selected && location === 2) {
			gtSities(token, brand_id, Number(department?.selected), Number(city?.selected)).then((sities) => {				
				if (sities?.data?.response && sities.data.response.error === false) {

					setSite({
						list: sities.data.response.message,
						selected: null
					})
				}

			}).catch(error => {
				console.log("ERROR SITIOS:", error)
			})
		}
	}, [city?.selected])

	return (
		<div className='FormQuantumData'>


			<div className="dataQuantum">
				{
					(location && location === 1) 
						?
						<div>
							<Input value={quantumName} onChange={handleChangeName} type={'dataUser'} placeholder={'Nombre completo'} />
							<Input value={quantumDoc} onChange={handleChangeDoc} type={'dataUser'} placeholder={'Número de documento'} />
						</div>
						:
						(location && location === 2) &&
							<div>
								<Input list={department?.list} onChange={handleChangeDepartment} type={'department'} placeholder={'Departamento'} />
								<Input disabled={!department?.selected && true} list={city?.list} onChange={handleChangeCity} type={'city'} placeholder={'Ciudad'} />
								<Input disabled={(!city?.selected || !department?.selected) && true} list={site?.list} onChange={handleChangeSite} type={'site'} placeholder={'Sector'} />
							</div>														
				}

			</div>
		</div>
	)
}

export default FormQuantumData
