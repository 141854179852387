import React, { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Slider from "react-slick"

import { StateController } from './../../App'
import AwardBox from '../molecules/AwardBox'
import Button from '../atoms/Button'
import { itsFiltered }  from '../../utils/functions'

function AwardsGallery({
  awardBoxType,
  itemsPerLine,
  limit,
  limitButtonText,
  list,
  canRedem,
  termText,
  termLink,
  termModal,
  redemtionText,
  steps,
  showStock,
  download,
  twoStepAuth,
  setShowSuccessDownload,
  setBase64Pdf,
  setLinkAnchor,
  isThree,
  isRouletteRedemtion,
  closeInitModal,
  isSaved
}) {
  const {context} = useContext(StateController)
  const [opts, setOpts] = useState([])
  const [currentGallery, setCurrentGallery] = useState(false)

  useEffect(() => {
    if (context && context.awards) {
      setOpts(
        Object.keys(context.awards).map((key) => context.awards[key])
      )
    }
  }, [context])

  const stepper_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    rows: 2,
    slidesPerRow: 1,
    customPaging: function(i) {
      return (
        <span key={i}>
          <p>{`${i+1}`}</p>
        </span>
      )
    }
  }

  const galleryToggler = (e) => {
    setCurrentGallery(e.target.value)
  }

  return (
    <div className={`mc-awards-container mc-awards-column-${itemsPerLine}`}>

      {context && context.allAwards && context.allAwards[0] && 
        <div className="mc-awards-container__selector">
          <select name="gallery-selector" id="gallery-selector" value={currentGallery} onChange={galleryToggler}>
            <option value="">Filtrar por valor</option>
            {itsFiltered(20000, context.availableForRedemtion) && <option value="20000">$20.000</option>}
          </select>
        </div>
      }
        
      {steps
        ? <div className="mc-awards-container__steper">
            <Slider {...stepper_settings}>
              {opts.map((step, index) => {

                return (
                  <div className="mc-awards-container__items" key={index}>
                    {step.awards.map((item, index) => {
                      
                      return (
                        <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                          <AwardBox
                            type={awardBoxType}
                            award={item}
                            canRedem={step.canRedem ? step.canRedem : false}
                            termLink={termLink}
                            termText={termText}
                            termModal={termModal}
                            redemtionText={redemtionText}
                            setShowSuccessDownload={setShowSuccessDownload}
                            setBase64Pdf={setBase64Pdf}
                            setLinkAnchor={setLinkAnchor}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Slider>
          </div>
        : list && list[0] 
        ? <div className="mc-awards-container__items">
            {list.filter((item, i) => {

              if (isSaved) {
                if (context && context.user && context.user[`award_${context.block}_level_1`]) {
                  item['price'] = context.user[`award_${context.block}_level_1`]
                }
              }

              if (isThree) {
                if (item.block === 3 || item.block === 6) {
                  return true
                } else {
                  return false
                }
              } else {
                if (item.block !== 3 && item.block !== 6) {
                  return true
                } else {
                  return false
                }
              }
            }).map((item, index) => {

              if (download && item.prepurchased === 1) item.prepurchased = true
              if (download && item.prepurchased === 0) item.prepurchased = false

              return (
                <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                  <AwardBox
                    index={index}
                    type={awardBoxType}
                    award={item}
                    canRedem={download ? true : canRedem}
                    termLink={termLink}
                    termText={termText}
                    termModal={termModal}
                    redemtionText={redemtionText}
                    showStock={showStock}
                    download={download}
                    twoStepAuth={twoStepAuth}
                    setShowSuccessDownload={setShowSuccessDownload}
                    setBase64Pdf={setBase64Pdf}
                    setLinkAnchor={setLinkAnchor}
                    isRouletteRedemtion={isRouletteRedemtion}
                    isSaved={isSaved}
                  />
                </div>
              )
            })}
          </div>
        : <>
            {download
              ? <div className='mc-awards-container__empty'>
                  {/* <h3>Aún no has descargado bonos</h3>''''' */}
                  {window.innerWidth < 992
                    ? <p>Aún no has realizado ninguna redención, continúa usando tus Tarjeta Débito y Crédito Mastercard AV Villas, revisa tu progreso y redime tus premios.</p>
                    : <p><strong>Aún no has descargado bonos</strong>Completa tu meta y descarga tu bono, aquí guardamos una copia para ti.</p>

                  }
                  <img src='/assets/images/loading.png' />
                  <p>¿Ya alcanzaste tu meta? Revisa los bonos disponibles.</p>
                  <Button
                    type="primary"
                    text="Ir a bonos"
                    status="active"
                    link="/premios"
                  />
                </div>
              : <>No hay premios disponibles.</>
            }
          </>
      }

      {limit && limitButtonText && (list && list[0]) && 
        <div className="mc-awards-container__actions">
          <Link to="/premios">{limitButtonText}</Link>
        </div>
      }
    </div>
  )
}

export default AwardsGallery