import React, { useContext } from 'react'
import { getTextTypeCard } from '../../utils/functions'
import { StateController } from '../../App'
import { Link } from 'react-router-dom'

function DynamicBanner({
  push70,
  preSecondOportunity
}) {
  const {context} = useContext(StateController)
  
  return (
    <div className={`mc-dynamic-banner-container`}>

      {push70 && preSecondOportunity 
        ? <img src={`/assets/images/banner-70${window.innerWidth > 992 ? '-desktop':''}.png`} alt="" />
        : <img src={`/assets/images/dynamic-banner${window.innerWidth > 992 ? '-desktop':''}.png`} alt="" />
      }

      {push70 && preSecondOportunity 
        ? <div className='mc-dynamic-banner-container__labels push70'>
            <h2>¿Compras que suman el doble para tu meta?</h2>
            <p>Claro, las compras por internet realizadas del <strong>1 al 15 de noviembre. ¡Aprovecha ya!</strong></p>
          </div>
        : <div className='mc-dynamic-banner-container__labels'>
            <h2>¡Compra lo que te gusta y cumple tu meta!</h2>
            <p>Desde mercado hasta antojitos, aprovecha los beneficios de pagar con tu Tarjeta {getTextTypeCard(context)} Mastercard AV Villas. <strong><Link to={'/alianzas'} className='custom-link'>Conocer alianzas</Link></strong></p>
          </div>
      }
    </div>
  )
}

export default DynamicBanner