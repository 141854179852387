import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import PageHeaders from '../atoms/PageHeaders'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Button from '../atoms/Button'
import Levels from '../molecules/Levels'
import Preloader from '../atoms/Preloader'
import DynamicBanner from '../molecules/DynamicBanner'
import { getPrice, getLevelName, getLevel, getTextTypeCard, sendDataLayer, getLevelPercentByOne, getDayAllies } from '../../utils/functions'
import Roulette from '../atoms/Roulette'
import CounterDown from '../atoms/CounterDown'
import CustomModal from '../molecules/CustomModal'

// components
import FullScreenModal from '../molecules/FullScreenModal'
import Wheel from '../molecules/Wheel'
import Columns, { Col } from '../../layouts/Columns/Columns'

function Progress() {
  const {context} = useContext(StateController)
  const [showPushGoal, setShowPushGoal] = useState(false)
  const [levelWinner, setLevelWinner] = useState(false)
  const [showRoulette, setShowRoulette] = useState(false) // pass to true?

  const [progress1, setProgress1] = useState(0)
  const [progress2, setProgress2] = useState(0)
  const [canRedem1, setCanRedem1] = useState(false)
  const [canRedem2, setCanRedem2] = useState(false)

  const [rouleteAwards, setRouleteAwards] = useState(false)
  const [completeAwards, setCompleteAwards] = useState(false)
  const [rouletteWinner, setRouletteWinner] = useState(false)

  const [hasRouletteAward, setHasRouletteAward] = useState(false)
  const [showRouletteOnboarding, setShowRouletteOnboarding] = useState(false)

  const [alreadyRunner, setAlreadyRunner] = useState(false)
  const [goalRoulette, setGoalRoulette] = useState(false)
  const [progressRoulette, setProgressRoulette] = useState(false)
  const [block1Redemtion, setBlock1Redemtion] = useState(false)
  const [showPush70, setShowPush70] = useState(false)
  const [preSecondOportunity, setPreSecondOportunity] = useState(false)
  const [showModalMoment1, setShowModalMoment1] = useState(false)
  const [showModalMoment2, setShowModalMoment2] = useState(false)

  const [counterDays, setCounterDays] = useState(0)
  const [counterHours, setCounterHours] = useState(0)
  const [end, setEnd] = useState(false)
  

  useEffect(() => {
    if (context && Array.isArray(context.alliances)) {

      const formater = context.alliances.map((item) => {
        
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          rule: `${item.rule} dto`,
          image: item.image,
          termsData: item.terms,
          img_rule: item.img_rule,
          link: item.link,
          sub_description: item.sub_description,
          activate: item.activate,
          banner: item.banner,
          link_file: item.link_file,
        }
      })
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (context) {
      if (context.tracing && context.tracing[`winner_${context.block}_level_${getLevel(context)}`] && context.tracing[`winner_${context.block}_level_${getLevel(context)}`] === 1) {
        setLevelWinner(true)
      }
      let isPreBlock = false
      if (context?.aditional_info?.status?.pre_block) {
        setPreSecondOportunity(true)
        isPreBlock = true

        if (
          context?.awards[`block_1_level_${getLevel(context)}`]?.canRedem &&
          context?.tracing[`winner_1_level_${getLevel(context)}`]
        ) {
          setShowModalMoment1(false)
          setShowModalMoment2(true)
        } else {

          const redemtionsBlock1 = context.redemtions.find((redemtion) => redemtion.block = 1);
          if (redemtionsBlock1) {
            setShowModalMoment1(false)
          } else {
            setShowModalMoment1(true)
          }
          
        }
      }

      if (!localStorage.getItem('ShowPushGoal') && context.stage === 2) {
        setShowPushGoal(true)
        localStorage.setItem('ShowPushGoal','true')
      }

      if (context.tracing) {
        setProgress1(
          Number(getLevelPercentByOne(context, 1, isPreBlock))
        )
        setProgress2(
          Number(getLevelPercentByOne(context, 2, isPreBlock))
        )
        setCanRedem1(
          context.awards[`block_${context.block}_level_1`]?.canRedem
        )
        setCanRedem2(
          context.awards[`block_${context.block}_level_2`]?.canRedem
        )
        setProgressRoulette(
          context.tracing[`amount_extra_${context.block}`]
        )
      }
      
      if (context.tracing && context.tracing[`winner_extra_${context.block}`]) {
        console.log("Ganador ruleta!")
        setRouletteWinner(true)
      }

      if (context.awards && context.awards[`extra_${context.block}`]) {
        setCompleteAwards(context.awards[`extra_${context.block}`].awards)

        let pre = []
        for (let i = 0; i < context.awards[`extra_${context.block}`].awards.length; i++) {
          const awrd = context.awards[`extra_${context.block}`].awards[i]
          
          if (
            awrd.id === 76 || 
            awrd.id === 75 || 
            awrd.id === 41 || 
            awrd.id === 36 || 
            awrd.id === 69 || 
            awrd.id === 49 || 
            awrd.id === 67 || 
            awrd.id === 53
          ) {
            pre.push({
              award_id: awrd.id,
              award_name: awrd.name,
              award_image: `rul-${awrd.image}`,
              award_value: awrd.price
            })
          }

          
        }
        setRouleteAwards(pre)
      }

      const hasRoulette = context.redemtions.find((item) => item.block === 6)
      if (hasRoulette) setHasRouletteAward(true)

      if (localStorage.getItem('rouletteSaved')) {
        setAlreadyRunner(
          JSON.parse(localStorage.getItem('rouletteSaved'))
        )
      }

      if (context.user) {
        setGoalRoulette(
          context.user[`goal_extra_${context.block}`]
        )
      }

      if (!localStorage.getItem('onboardingviewed') && (context && context.user[`goal_extra_1`] > 0)) {
        setShowRouletteOnboarding(true)
      }

      if (context.redemtions) {
        const block1Award = context.redemtions.find((redemtion) => redemtion.block === 1)
        if (block1Award) setBlock1Redemtion(true)
      }

      if (context.tracing?.extra2 === "1") {
        setShowPush70(true)
      }

      if (context && context.counter && context.counter.value != null) {
        const countDownDate = new Date(context.counter.value).getTime()
  
        const startCountdown = () => {
          const timer = setInterval(function() {
            let now = new Date().getTime();
            let distance = countDownDate - now;
              
            if (distance < 0) {
              setEnd(true)
              clearInterval(timer)
            }
  
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          
            setCounterDays(days)
            setCounterHours(hours)
          }, 1000)
        }
        startCountdown()
      }

      

    }
  }, [context])

  const openLevelModal = () => {
    sendDataLayer({datalayer:{ event: "progress", text: "Decidir ya", link: "/" }, repeat: false})
    setShowPushGoal(true)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }

  const openModalRoulette = () => {
    setShowRoulette(true)
  }

  const dataLayerRuleta = ()=>{
    sendDataLayer({
      datalayer: {
        event: "spin_roulette"
      },repeat: true})
  }
  return (
    <div className='mc-page progress slow_ani'>


      <CustomModal
        title="¡Sigue pagando <br />con tus tarjetas!"
        formatTitle
        image={'icon-modal-gracias.png'}
        globalModal
        desc={`Tu progreso anterior fue de <strong>${getPrice(context?.tracing?.amount_1)}</strong>, pronto sabrás si ganaste en la primera oportunidad.`}
        cta="Aceptar"
        show={showModalMoment1} setShowModal={setShowModalMoment1}
      />

      {/* <CustomModal
        title="¡Ganaste!"
        image={'icon-modal-gracias.png'}
        globalModal
        desc={`Lograste un progreso de <strong>${getPrice(context?.tracing?.amount_1)}</strong> Tienes <strong>${counterDays} dias y ${counterHours} horas</strong> horas para redimir tu bono. Si la ruleta está disponible, gírala para seguir ganando.`}
        cta="Redimir bono"
        link="/premios"
        show={showModalMoment2} setShowModal={setShowModalMoment2}
      /> */}


      


      <FullScreenModal
        show={showPushGoal}
        setShowModal={setShowPushGoal}
        context={context}
        steps={
          [
            {
              title: `¿Listo para comenzar?<br /> <span>Tu ${ context.block === 1 ? 'primera' : 'segunda'} meta está aquí</span><br />`,
              text: "Alcánzala y podrás redimir un bono en tu comercio favorito o seguir acumulando para ganar mucho más."
            }, 
            {
              title: "<span>¡Tu meta, tus reglas!</span><br /> Decide hasta cuanto quieres ganar",
              text: "Acumula tus compras y alcanza el nivel al que quieres llegar:",
              winnerTitleLevel1: "<span>¡Tu meta, tus reglas!</span><br /> ¡Felicidades! Has alcanzado tu meta de nivel 1",
              winnerSubtitleLevel1: "¿Quieres redimir tu bono, o aceptas la meta de nivel 2 para mejorar tu premio?"
            },
            {
              title: "<span>¡Gira y gana más!</span>",
              text: `Haz al menos <strong class='color-red'>${(context && context.user) ? context.user[`goal_extra_${context.block}`] : '<5>'}</strong> transacciones por internet con <tus Tarjetas Débito y Crédito> Mastercard AV Villas y podrás ganar un bono adicional. Además, tendrás la oportunidad de <b>girar la ruleta para redimir tu premio.</b>`,
              text3:'*Tienes hasta el 31 de enero de 2025 para cumplir este reto.'
            },
          ]
        }
      />

      <FullScreenModal
        show={showRouletteOnboarding}
        setShowModal={setShowRouletteOnboarding}
        isRoulette={true}
        awards= {rouleteAwards}
        completeAwards={completeAwards}
        rouletteWinner={false}
        alreadyRunner={false}
        onlyOnboarding={true}
        goalRoulette={goalRoulette}
      />

      {(!hasRouletteAward && context) && 
        <FullScreenModal
          show={showRoulette}
          setShowModal={setShowRoulette}
          isRoulette={true}
          awards= {rouleteAwards}
          completeAwards={completeAwards}
          rouletteWinner={rouletteWinner}
          alreadyRunner={alreadyRunner}
          goalRoulette={goalRoulette}
        />
      }

      {preSecondOportunity
        ? (context?.stage == 2)
            ? <PageHeaders
                title={`Esta es tu <span>segunda oportunidad</span>`}
                text={`<p>Tu progreso anterior fue de <strong>${getPrice(context?.tracing?.amount_1)}</strong>,<span> pronto sabrás si ganaste.</span></p><p>Estamos en fase de redención de la primera oportunidad, pronto habilitaremos el nuevo catálogo de premios.</p>`}
              />
            : <PageHeaders
                title={`<span>Conoce tu nivel</span><br /> y progreso`}
                text="Alcanza tu meta y desbloquea nuevas recompensas. <br />"
              />
        : <PageHeaders
            title={`<span>Conoce tu nivel</span><br /> y progreso`}
            text={`Alcanza tus metas y desbloquea nuevas recompensas. ${(context && getLevel(context) == 2) ? '' : '<br /> ¿Te atreves a subir de nivel?'}`}
          />
      }
       

      <h1 className='mc-page-title'>Tu nivel</h1>

      {context
        ? <Container>
          <p>{}</p>
            <Levels
              levels={
                [
                  {
                    image: 'level-1.png',
                    title: getLevelName(1),
                    subtitle: 'Meta',
                    amount: preSecondOportunity ? getPrice(context.user[`goal_2_level_1`]) : getPrice(context.user[`goal_${context.block}_level_1`]),
                    active: preSecondOportunity ? true : getLevel(context) === 1 ? true : false
                  },
                  {
                    image: 'level-2.png',
                    title: getLevelName(2),
                    subtitle: 'Meta',
                    amount: preSecondOportunity ? getPrice(context.user[`goal_2_level_2`]) : getPrice(context.user[`goal_${context.block}_level_2`]),
                    active: preSecondOportunity ? false : getLevel(context) === 2 ? true : false
                  }
                ]
              }
            />
          </Container>
        : <Preloader
            type="section"
            invert
          />
      }

      {context
        ? <>
            <h1 className='mc-page-title'>Tu progreso</h1>
            <h2 className='mc-page-title'>
              Llevas: <strong>{
                preSecondOportunity 
                  ? getPrice(context.tracing ? context.tracing[`amount_2`] : 0 ) 
                  : getPrice(context.tracing ? context.tracing[`amount_${context.block}`] : 0 )}
                </strong> de <br /><strong>{
                  preSecondOportunity
                    ? getPrice(context.user[`goal_2_level_${getLevel(context)}`])
                    : getPrice(context.user[`goal_${context.block}_level_${getLevel(context)}`])
                }</strong>
            </h2>
            <h3 className='mc-page-title'>Tienes hasta el <span>31/01/2025</span> para cumplir tu meta.</h3>
            
          </>
        : <Preloader
            type="section"
            invert
          />
      }

      {context
        ? <Container>

            <div className='mc-levels-container-v2'>
              <div className='mc-levels-container-v2__line'>

                <div className={`mc-levels-container-v2__line--item lvl-1 ${canRedem1 ? 'check' : ''} ${getLevel(context) === 1 ? 'active' : ''}`}>
                  <img src={`/assets/images/level-1.png`} />
                  <img src="/assets/images/check_level.png" className='check-level' />
                </div>
                <div className={`mc-levels-container-v2__line--item lvl-2 ${canRedem2 ? 'check' : ''} ${getLevel(context) === 2 ? 'active' : ''}`}>
                  <img src={`/assets/images/level-2.png`} />
                  <img src="/assets/images/check_level.png" className='check-level' />
                </div>
                <div className={`mc-levels-container-v2__line--item lvl-extra ${canRedem2 ? 'active': ''}`}>
                  <img src={`/assets/images/extra.png`} />
                  <img src="/assets/images/check_level.png" className='check-level' />
                </div>

                <div
                  className='mc-levels-container-v2__line--progress-1'
                  style={{"width": `${progress1 > 98 ? 45 : progress1 / 2}%`}}
                ></div>
                <div 
                  className='mc-levels-container-v2__line--progress-2'
                  style={{"width": `${(progress2 / 2) > 48 ? 48 : progress2 / 2}%`}}
                ></div>

              </div>
            </div>
          </Container>
        : <Preloader
            type="section"
            invert
          />
      }
      
      {context
        ? <h4 className='mc-page-title'>Tus compras tomarán <span>7</span> días en verse reflejadas. Última actualización: <span>{context.tracing ? context.tracing.date_update : 'Sin actualizar'}</span></h4>
        : <Preloader
            type="section"
            invert
          />
      }

      <div className='mc-mini-divider' />
      {context && !block1Redemtion && 
        <h5 className='mc-page-title'>
          {levelWinner
            ? `${(getLevel(context) === 2 && canRedem2) ? 'Una vez cumplas tu meta podrás redimir tu premio.' : ''}`
            : `${getLevel(context) === 1 ? 'Cuando alcances tu meta de nivel 1, podrás decidir si quieres ganar un premio más alto o redimir tu bono.' : preSecondOportunity ? 'Cuando alcances tu meta de nivel 1, podrás decidir si quieres ganar un premio más alto o redimir tu bono.' : 'Una vez cumplas tu meta podrás redimir tu premio.'}`
          }
        </h5>
      }

      <Container>
        {
          (getLevel(context) === 2 && canRedem2)
            ? <Button
                type="primary"
                text={'Redimir bono'}
                status={'active' }
                link={"/premios"}
              />
            : !block1Redemtion && 
              <Button
                type="primary"
                text={levelWinner ? 'Decidir ya' : 'Decidir al cumplir la meta'}
                status={levelWinner ? 'active' : 'inactive'}
                link={levelWinner ? false : "/premios"}
                onClick={levelWinner ? openLevelModal : false}
              />
        }
      </Container>
      {/* <div className='mc-max-divider' /> */}
      
      <Container>
        <PrimaryBanner
          className="banner-divider"
          image={getTextTypeCard(context) === 'Débito' ? 'cards_debito.png' : getTextTypeCard(context) === 'Crédito' ? 'cards_credito.png': getTextTypeCard(context) === 'Débito y Crédito' ? 'cards_all_segment.png':'cards_all_segment.png'}
        />
      </Container>

      
      {(!hasRouletteAward && !localStorage.getItem('rouletteSaved')) &&
        <div className='action-init-roulette'>
          <Container>
          <Columns desktop={2} mobile={1}>
          <Col>
          <PageHeaders
            className='custom_preheader'
            title={`<span>¿Quieres ganar más?</span> <br> ¡Descubre cómo!`}
            text={`Realiza mínimo <strong>${goalRoulette}</strong> transacciones por internet y podrás ganar un bono adicional.`}
          />

          <div className='action-init-roulette__title'>
            Llevas: <span>{progressRoulette}</span> de <span>{goalRoulette}</span>
          </div>

          <div className='action-init-roulette__banner mobile'>
            {/* <Roulette
              chosenAward={false}
              awards={rouleteAwards}
              run={{}}
              disable={true}
            /> */}
            {context &&
              <img src={`/assets/images/rlt-${context.user[`award_extra_${context.block}`]}.png`} />
            }
            
            
          </div>

          <div className='action-init-roulette__subtitle'>
            <p>*Tienes hasta el 31 de enero de 2025 para cumplir este reto.</p>
          </div>
          
          <div onClick={dataLayerRuleta} className='action-init-roulette__actions'>
            <Button
              type="primary"
              id="girar-ruleta"
              text={rouletteWinner ? 'Girar la ruleta ahora' : 'Gira al cumplir tu meta'}
              status={rouletteWinner ? 'active' : 'inactive'}
              onClick={rouletteWinner ? openModalRoulette : false}
            />
          </div>
          </Col>
          <Col>
            <div className='action-init-roulette__banner desk'>
            {/* <Roulette
              chosenAward={false}
              awards={rouleteAwards}
              run={{}}
              disable={true}
            /> */}
            {context &&
              <img src={`/assets/images/rlt-${context.user[`award_extra_${context.block}`]}.png`} />
            }
          </div>
          </Col>
          </Columns>
          </Container>
        </div>
      }
      



      {(hasRouletteAward || localStorage.getItem('rouletteSaved')) &&
        <div style={{"backgroundImage": `url(/assets/images/bg-has-roulette${window.innerWidth > 992 ? '-desktop':''}.png)`}}>
          <Container className="has-roulete-award">
            <h2>
              <span>¡Ya giraste la ruleta!</span>< br />
              {
                localStorage.getItem('rouletteSaved') && (!hasRouletteAward) 
                  ? 'Encuentra tu bono en el catálogo de premios' 
                  : 'Encuentra tu bono en premios redimidos'
                }
            </h2>
            <Button
              type="primary"
              text="Ir por mi bono"
              status="active"
              link={`/${!hasRouletteAward ? 'premios' : 'mis-redenciones'}`}
            />
          </Container>
        </div>
      }

      <Container>
        {context &&
          <>
            {showPush70
              ? <DynamicBanner context={context} push70={true} />
              : <DynamicBanner context={context} preSecondOportunity={preSecondOportunity} />
            }
          </>
        }
      </Container>

      {/* {context
        ? <PageHeaders
            title="<span>Gira y gana</span> con nuestra<br /> ruleta de premios!"
            text={`Alcanza <strong>${getPrice(context.user[`goal_extra_${context.block}`])}</strong> en tus compras digitales y gira la ruleta.`}
          /> 
        : <Preloader
            type="section"
            invert
          />
      }

      {context
        ? <h2 className='mc-page-title'>Llevas: <strong>{getPrice(context.tracing ? context.tracing[`amount_extra_${context.block}`] : 0)}</strong> de <br /><strong>{getPrice(context.user[`goal_extra_${context.block}`])}</strong></h2>
        : <Preloader
            type="section"
            invert
          />
      } */}

      {/* <Container>
        <PrimaryBanner
          image="ruleta.png"
        />
        <div className='mc-medium-divider' />
        <Button
          type="primary"
          text="Redimir bono"
          status="inactive"
          link="/premios"
        />
      </Container> */}
      
    </div>
  )
}
export default Progress