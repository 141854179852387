import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RECAPCHAKEY, GLOBAL_CODE } from "../../constants/constants"

import Container from '../../layouts/Container/Container'
import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Preloader from '../atoms/Preloader'

// actions
import { loginAction } from './../../store/sessionDuck'
import { sendDataLayer } from '../../utils/functions'

function LoginForm({
  type,
  fields,
  buttonText,
  loginAction,
  autologin,
  hash,
  setAutologinPreloader,
  autologinPreloader
}) {
  const [document, setDocument] = useState('')
  const [emailPhone, setEmailPhone] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [code, setCode] = useState('')
  const [card, setCard] = useState('')
  const [terms, setTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  
  const [errors, setErrors] = useState({
    document: false,
    emailPhone: false,
    birthdate: false,
    code: false,
    card: false,
    terms: false,
    termsData: false,
    login: false
  })
  const [gToken, setGToken] = useState(false)

  const handleChange = async (props) => {    
    const element = props.target.getAttribute('rel')
    const field = fields.find((field) => field.field === element)    

    if (element === 'code') {
      if (props.target.value.length < field.maxlength+1) {
        setCode(props.target.value)
        setErrors({ ...errors, code: false})
      }
    }
    if (element === 'document') {
      const numbers = /^\d+$/

      if ((numbers.test(props.target.value) || props.target.value === '') && props.target.value.length < field.maxlength+1) {
        setDocument(props.target.value)
        setErrors({ ...errors, document: false})
      }
    }
    if (element === 'birthdate') {
      setBirthdate(props.target.value)
      setErrors({ ...errors, birthdate: false})
    }    
    if (element === 'emailPhone') { 
        setEmailPhone(props.target.value)
        setErrors({ ...errors, emailPhone: false})  
      
    }
    if (element === 'card') {
      const numbers = /^\d+$/

      if ((numbers.test(props.target.value) || props.target.value === '') && props.target.value.length < 6) {
        setDocument(props.target.value)
        setErrors({ ...errors, card: false})
      }
    }
    if (element === 'terms') {
      setTerms(props.target.checked)
      setErrors({ ...errors, terms: false})
    }
  }

  const handleLogin = async () => {
    sendDataLayer({datalayer: { event: "button_login" },repeat: true})
    setIsLoading(true)
    const field_code = fields.find((field) => field.field === 'code')
    const field_card = fields.find((field) => field.field === 'card')
    const field_document = fields.find((field) => field.field === 'document')
    const field_emailPhone = fields.find((field) => field.field === 'emailPhone')
    const field_birthdate = fields.find((field) => field.field === 'birthdate')
    const field_terms = fields.find((field) => field.field === 'terms')

    if (field_code && code.length <= (field_code ? field_code.maxlength-1 : 10)) {
      setIsLoading(false)
      setErrors({ ...errors, code: 'Inserta tu código.'})      
      return false
    }
    if (field_document && document.length <= (field_document ? field_document.maxlength-1 : 4)) {
      setIsLoading(false)
      setErrors({ ...errors, document: 'Inserta un documento válido.'})
      sendDataLayer({datalayer: { event: 'error_login' }, repeat: true})
      return false
    }
    if (field_emailPhone) {  
      const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/ 
      const numbers = /^\d+$/  
      if (emailPhone.includes('@')) {
        if (!isEmail.test(emailPhone)) {
          setIsLoading(false)
          setErrors({ ...errors, emailPhone: 'Inserta un correo válido.'})
          return false        
        }        
      }else if ((numbers.test(emailPhone) && (emailPhone.length < 10 || emailPhone.length > 10))) {
        setIsLoading(false)
        setErrors({ ...errors, emailPhone: 'Inserta un número de celular válido.'})
        return false         
      }
    }

    if (field_card && card.length <= 4) {
      setIsLoading(false)
      setErrors({ ...errors, card: 'Inserta un número válido.'})
      return false
    }

    if (field_birthdate && birthdate === "") {
      setIsLoading(false)
      setErrors({ ...errors, birthdate: 'Ingresa tu fecha de nacimiento.'})
      sendDataLayer({datalayer: { event: 'error_login' }, repeat: true})
      return false
    }

    if (field_terms && !terms) {
      setIsLoading(false)
      setErrors({ ...errors, terms: 'Debes aceptar nuestros términos y condiciones.'})
      sendDataLayer({datalayer: { event: 'error_login' }, repeat: true})
      return false
    }    
    
    loginAction({
      campaignCode: GLOBAL_CODE,
      type: "credentials",
      credentials: {
        document: document,birthdate: birthdate
      },
      actions: "none"
    }, gToken)

    setTimeout(() => {
      // setExtraLoginPreloader(true)
      setAutologinPreloader(true)
    }, 5000)
  }

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(RECAPCHAKEY, { action: "login" }).then(token => {
        setGToken(token)
      })
    })
  }

  useEffect(() => {
    const script = window.document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPCHAKEY}`
    script.addEventListener("load", handleLoaded)
    window.document.body.appendChild(script)

    setCard('')
  }, [])

  useEffect(() => {
    if (hash && hash !== '') {
      setIsLoading(true)

      if (!localStorage.getItem('userNotFound')) {
        loginAction({
          campaignCode: GLOBAL_CODE,
          type: "autologin",
          credentials: {
            code_hash: hash
          },
          actions: "none"
        }, gToken)
      }
    }
  }, [hash])
  

  return (
    <div className={`mc-login mc-login-${type}`}>

      <div
        className="g-recaptcha"
        data-sitekey={RECAPCHAKEY}
        data-size="invisible"
      ></div>

      {(autologinPreloader || autologin) &&
        <Preloader
          type="fullscreen"
          invert={true}
        />
      }

      <Container className="full-height">
        <div className='mc-login__form'>
          {isLoading &&
            <div className='mc-login__form--overlay slow_ani'></div>
          }

          <h3>¿Listo para conectar tu forma de <span>pagar y ganar?</span></h3>
          
          <div className='mc-login__form--fields'>
            {fields.map((field, index) => {
              
              return (
                <Input 
                  key={index}
                  type={field.field}
                  label={field.label}
                  placeholder={field.placeholder}
                  termsText={field.termsText}
                  termsLink={field.termsLink}
                  onChange={handleChange}
                  inputByCharacter={field.separated ? field.separated : false}
                  maxlength={field.maxlength}
                  value={field.field === 'document' ? document : field.field === 'birthdate' ? birthdate : field.field === 'terms' ? terms : field.field === 'code' ? code : field.field === 'emailPhone' ? emailPhone : false}
                  error={field.field === 'document' ? errors.document : field.field === 'birthdate' ? errors.birthdate : field.field === 'terms' ? errors.terms : field.field === 'code' ? errors.code : field.field === 'emailPhone' ? errors.emailPhone : false}
                />
              )
            })}
          </div>
          <Button
            type="primary"
            text={buttonText}
            loading={isLoading}
            status="active"
            onClick={handleLogin}
          />
        </div>
      </Container>

    </div>
  )
}

const mapState = ({ user: { token } }) => {

  return {
    token
  }
}

export default connect(mapState, { loginAction })(LoginForm)