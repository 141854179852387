import React, { useEffect, useState } from 'react'

import { arrNum } from '../../utils/functions'

function Input({
  type,
  label,
  placeholder,
  termsText,
  termsLink,
  onChange,
  value,
  error,
  inputByCharacter,
  maxlength,
  list,
  disabled,
}) {
  const [separatedInputs, setSeparatedInputs] = useState([])

  const onChangeSeparated = async (props) => {
    const index = Number(props.target.getAttribute('rel'))
    const cur = JSON.parse(JSON.stringify(separatedInputs))
    const newC = cur.map((item, i) => i == index ? props.target.value : item)
    setSeparatedInputs(newC)
  }

  const onInputSeparated = async (props) => {

    if (props.target.nextElementSibling) {
      props.target.nextElementSibling.focus()
    }
  }

  useEffect(() => {
    const datePick = document.querySelector('#datePickerId')
    if (datePick) {
      const todayDate = new Date('2025-01-01')
      const limitDate = new Date('1900-01-01')
      datePick.max = new Date(todayDate.setDate(todayDate.getDate() - 1)).toISOString().split("T")[0]
      datePick.min = new Date(limitDate.setDate(limitDate.getDate() - 1)).toISOString().split("T")[0]
    }

    if (inputByCharacter && separatedInputs.length === 0) {

      const arr = []
      for (let i = 0; i < arrNum(maxlength).length; i++) arr.push("")
      setSeparatedInputs(arr)
    }
  }, [])

  return (
    <div className="mc-input-container">
      <div className={`mc-input-container__box ${type} ${error ? 'mc-input-container__box--error' : ''}`}>
        <label>{label}</label>

        {type === 'code'
          ? inputByCharacter
            ? <div className='mc-input-separated-container'>
              {arrNum(maxlength).map((i) => {

                return (
                  <input
                    key={i}
                    type="text"
                    rel={i}
                    style={{ width: `${(100 / maxlength) - 2}%` }}
                    maxLength={1}
                    onChange={onChangeSeparated}
                    onInput={onInputSeparated}
                    value={separatedInputs[i] ? separatedInputs[i] : ''}
                  />
                )
              })}
            </div>
            : <input
              type="text"
              rel={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />
          : type === 'document'
            ? inputByCharacter
              ? <div className='mc-input-separated-container'>
                {arrNum(maxlength).map((i) => {

                  return (
                    <input
                      key={i}
                      type="text"
                      rel={i}
                      style={{ width: `${(100 / maxlength) - 2}%` }}
                      maxLength={1}
                      onChange={onChangeSeparated}
                      onInput={onInputSeparated}
                      value={separatedInputs[i] ? separatedInputs[i] : ''}
                    />
                  )
                })}
              </div>
              : <input
                type="text"
                rel={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
              />
            : type === 'birthdate'
              ? <>
                  <input
                    type="date"
                    id="datePickerId"
                    rel={type}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M3.1665 4.9012C3.1665 4.54758 3.30698 4.20844 3.55703 3.9584C3.80708 3.70835 4.14622 3.56787 4.49984 3.56787H12.4998C12.8535 3.56787 13.1926 3.70835 13.4426 3.9584C13.6927 4.20844 13.8332 4.54758 13.8332 4.9012V12.9012C13.8332 13.2548 13.6927 13.594 13.4426 13.844C13.1926 14.0941 12.8535 14.2345 12.4998 14.2345H4.49984C4.14622 14.2345 3.80708 14.0941 3.55703 13.844C3.30698 13.594 3.1665 13.2548 3.1665 12.9012V4.9012Z" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.1665 2.23438V4.90104" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.8335 2.23438V4.90104" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.1665 7.56787H13.8332" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.8335 10.901C7.8335 11.0779 7.90373 11.2474 8.02876 11.3724C8.15378 11.4975 8.32335 11.5677 8.50016 11.5677C8.67697 11.5677 8.84654 11.4975 8.97157 11.3724C9.09659 11.2474 9.16683 11.0779 9.16683 10.901C9.16683 10.7242 9.09659 10.5547 8.97157 10.4296C8.84654 10.3046 8.67697 10.2344 8.50016 10.2344C8.32335 10.2344 8.15378 10.3046 8.02876 10.4296C7.90373 10.5547 7.8335 10.7242 7.8335 10.901Z" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </>
              : type === 'terms'
                ? <>
                  <a href={termsLink}>{termsText}</a>
                  <input
                    type="checkbox"
                    rel={type}
                    onChange={onChange}
                    checked={value}
                  />
                  <span className="mc-input-checkbox__checkmark"></span>
                </>
                : type === 'textarea' ?
                  <textarea
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                  ></textarea>
                  :
                  type === 'emailPhone' ?
                    <input
                      type="text"
                      rel={type}
                      placeholder={placeholder}
                      onChange={onChange}
                      value={value}
                    />
                    :
                    type === 'dataUser' ?
                      <input
                        type="text"
                        rel={type}
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                      />
                      :
                      type === 'department' ?
                        <select disabled={disabled && true} onChange={onChange} className='selectDepartment' name="department">
                          <option selected value="" >{placeholder}</option>
                          {
                            list &&
                            list.map((item, i) => (
                              <option key={i} value={item?.dep_id}>
                                {item?.dep_name}
                              </option>
                            ))
                          }
                        </select>
                        :
                        type === 'city' ?
                          <select disabled={disabled && true} onChange={onChange} className='selectCity' name="city">
                            <option selected value="" >{placeholder}</option>
                            {
                              list &&
                              list.map((item, i) => (
                                <option key={i} value={item?.city_id}>
                                  {item?.city_name}
                                </option>
                              ))
                            }
                          </select>
                          :
                          type === 'site' &&
                          <select disabled={disabled && true} onChange={onChange} className='selectSite' name="site">
                            <option selected value="" >{placeholder}</option>
                            {
                              list &&
                              list.map((item, i) => (
                                <option key={i} value={item?.site_id}>
                                  {item?.site_name}
                                </option>
                              ))
                            }
                          </select>

        }

        {error &&
          <div className="mc-input-error">{error}</div>
        }
      </div>
    </div>
  )
}

export default Input