import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import AlliancesGallery from '../organisms/AlliancesGallery'
import Preloader from '../atoms/Preloader'
import PageHeaders from '../atoms/PageHeaders'
import { getDayAllies, getTextTypeCard } from '../../utils/functions'

function Alliances() {
  const [alliances, setAlliances] = useState(false)
  const {context} = useContext(StateController)

  useEffect(() => {
    if (context && context.alliances) {

      const formater = context.alliances.map((item) => {
        
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          rule: `${item.rule} dto`,
          image: item.image,
          termsData: item.terms,
          img_rule: item.img_rule,
          link: item.link,
          sub_description: item.sub_description,
          activate: item.activate
        }
      })
      
      setAlliances(formater)
    }
  }, [context])
  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page alliances slow_ani'>
      
      <PageHeaders
        title="<span>¡Estas alianzas son lo que</span> esperabas!<br />"
        text={`Paga con tu Tarjeta ${getTextTypeCard(context)} Mastercard AV Villas y aprovecha estos beneficios.`}
      />

      <Container className={'allies-container'}>        

        {alliances
          ? <AlliancesGallery
              alliedBoxType={1}
              itemsPerLine={3}
              limit={false}
              // termsOnModal={true}
              limitButtonText="Ver todos los aliados"
              list={alliances}
              termText="Aplican términos y condiciones."
              // termLink="/terminos-y-condiciones"
            />
          : <Preloader
              type="section"
              invert
            />
        }
        
      </Container>

    </div>
  )
}
export default Alliances