import React, { useEffect, useContext, useState } from 'react'
import { StateController } from './../../App'

import Icon from '../atoms/Icon'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'
import Levels from './Levels'
import { getPrice, getLevelName, getLevel } from '../../utils/functions'
import Preloader from '../atoms/Preloader'
import AwardsGallery from '../organisms/AwardsGallery'
import Roulette from '../atoms/Roulette'

import { stNewLevel, stExtra } from '../../utils/helpers'

function FullScreenModal({
  show,
  setShowModal,
  steps,
  context,
  isRoulette,
  awards,
  completeAwards,
  rouletteWinner,
  alreadyRunner,
  onlyOnboarding,
  goalRoulette
}) {
  const { setMdalOpen } = useContext(StateController)
  const [modalStep, setModalStep] = useState(1)
  const [level1Winner, setLevel1Winner] = useState(false)
  const [loadingNewLevel, setLoadingNewLevel] = useState(false)
  const [isSpinning, setIsSpinning] = useState(false)
  const [chosenAward, setChosenAward] = useState(false)
  const [globalAwardSelected, setGlobalAwardSelected] = useState(false)
  const [ruletteStep, setRuletteStep] = useState(0)

  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])

  useEffect(() => {

    if (rouletteWinner) {
      setRuletteStep(1)
    }
  }, [rouletteWinner])
  

  useEffect(() => {

    if (context) {
      console.log("Context", context)
      if (context.tracing && context.tracing[`winner_${context.block}_level_${getLevel(context)}`] && context.tracing[`winner_${context.block}_level_${getLevel(context)}`] === 1) {
        goToTwo(2)
        setLevel1Winner(true)
      }

    }
  }, [context])

  const close = (target) => {

    if (isRoulette) {
      setShowModal(false)
      
      if (onlyOnboarding) {
        localStorage.setItem('onboardingviewed', 'true')
      }

      if (localStorage.getItem('rouletteSaved')) {
        window.location.reload()
      }
    } else {
      setLoadingNewLevel(true)
      let token = localStorage.getItem('storage') ? JSON.parse(localStorage.getItem('storage')).user.token : ''
      
      stExtra(token).then((response) => {
        console.log('response extra change', response)
        setShowModal(false)
        // setLoadingNewLevel(false)
        target === '/premios' ? window.location.href= target : window.location.reload()
  
      }).catch((error) => {
        console.log('response extra change error:', error)
        setShowModal(false)
        setLoadingNewLevel(false)
      })
    }
  }

  useEffect(() => {

    console.log("Ya giró la ruleta?", alreadyRunner)
    if (alreadyRunner) {
      const awardSelected = completeAwards.filter((item) => item.id === alreadyRunner.award_id)
      setGlobalAwardSelected(awardSelected)
      setRuletteStep(3)
    }
  }, [alreadyRunner])
  
  const goToAwards = () => {
    close('/premios')
  }

  const goToFinish = () => {
    close()
  }

  const goToOne = () => {
    setModalStep(1)
  }

  const goToTwo = () => {
    setModalStep(2)
  }
  const goToThree = () => {
    setModalStep(3)
  }

  const goToLevel = () => {
    console.log("Aceptar nueva meta", getLevel(context))
    setLoadingNewLevel(true)

    let token = localStorage.getItem('storage') ? JSON.parse(localStorage.getItem('storage')).user.token : ''
    console.log("token", token)
 
    stNewLevel(token).then((response) => {
      console.log('response nex level', response)
      // setLoadingNewLevel(false)
      goToFinish()

    }).catch((error) => {
      console.log("Error update level", error)
      setLoadingNewLevel(false)
    })

  }

  const closeRoulette = () => {
    setShowModal(false)

    if (onlyOnboarding) {
      localStorage.setItem('onboardingviewed', 'true')
    }
    if (rouletteWinner) {
      window.location.reload()
    }

    if (localStorage.getItem('rouletteSaved')) {
      window.location.reload()
    }
  }

  const run = () => {
    console.log("Run this awards:", awards)
    setIsSpinning(true)
    const ramdomChoise = Math.floor(Math.random() * awards.length)

    if (!isSpinning) {
      console.log("Good Luck!", ramdomChoise)
      setChosenAward(ramdomChoise)

      const awardSelected = completeAwards.filter((item) => item.id === awards[ramdomChoise].award_id)
      setGlobalAwardSelected(awardSelected)

      setTimeout( () => {
        setRuletteStep(2)
        
        console.log("Premio:", awards[ramdomChoise])
        localStorage.setItem('rouletteSaved', JSON.stringify(awards[ramdomChoise]))
        setTimeout( () => {
          setRuletteStep(3)
        }, 2000 )
      }, 8000 )
    }
    
  }

  return (
    <div className={`
      mc-fullscreen-modal 
      ${show && 'mc-fullscreen-modal__opened'} slow_ani 
      ${loadingNewLevel ? 'loading' : ''} 
      ${isRoulette ? 'roulette' : ''} 
      ${onlyOnboarding ? 'onlyOnboarding' : ''}
    `}>
      <div className="mc-fullscreen-modal__center">
        <div className="mc-fullscreen-modal__close" onClick={close}>
          <Icon name="close" />
        </div>

        <a className="mc-modal-container__logo" href="/">
          <VerticalCenter>
            <img src={`/assets/images/logo.png`} alt="Logo Banco AV Villas" />
          </VerticalCenter>
        </a>

        {isRoulette
          ? <VerticalCenter className={`roulette-container step-${ruletteStep} status-${rouletteWinner ? 'winner' : 'no-winner'}`}>
              <h2>
                {ruletteStep === 1
                  ? '¡Gira y disfruta tu bono!'
                  : ruletteStep === 2 
                    ? '¡Ganaste!'
                    : ''
                }
              </h2>

              {ruletteStep === 0 && 
                <>
                  <div className='pre-roulette'>
                    {
                      onlyOnboarding
                        ? <div><span>¡Gira y gana más!</span></div>
                        : <div><span>¡Acepta el reto</span> y conecta tus compras por internet para ganar más!</div>
                    }
                    
                    {onlyOnboarding
                      ? <p>
                          Haz al menos <span>{goalRoulette}</span> transacciones por internet con tus Tarjetas Débito y Crédito Mastercard AV Villas y podrás ganar un bono adicional. Además, tendrás la oportunidad de <strong>girar la ruleta para
                          redimir tu premio.</strong>
                        </p>
                      : <p>
                          Haz mínimo <span>{goalRoulette}</span> transacciones por internet con tus Tarjetas Débito y Crédito Mastercard AV Villas, <strong>podrás ganar un bono adicional y girar la ruleta para redimir tu premio</strong>.
                        </p>
                    }
                  </div>

                  {!onlyOnboarding &&
                    <img src='/assets/images/ruleta-banner.png' className='pre-roulette-banner' />
                  }

                  {onlyOnboarding &&
                    <Roulette
                      chosenAward={chosenAward}
                      awards={awards}
                      run={run}
                      disable={true}
                    />
                  }

                  {onlyOnboarding &&
                    <p>*Tienes hasta el 31 de enero de 2025 para cumplir este reto.</p>
                  }

                  <Button
                    type="primary"
                    text={`${!onlyOnboarding ? 'Ver mi progreso' : 'Comenzar'}`}
                    loading={false}
                    status="active"
                    onClick={closeRoulette}
                  />
                </>
              }

              {ruletteStep < 3
                ? <Roulette
                    chosenAward={chosenAward}
                    awards={awards}
                    run={run}
                    disable={false}
                  />
                : <div className='mc-fullscreen-modal__award-redemtion'>
                    <div className='mc-fullscreen-modal__award-redemtion--title'>
                      Ganaste un bono de < br/>{globalAwardSelected[0]?.name}
                    </div>

                    <AwardsGallery
                      awardBoxType={1}
                      itemsPerLine={3}
                      limit={false}
                      limitButtonText="Ver más comercios"
                      showStock={false}
                      list={globalAwardSelected}
                      steps={false}
                      canRedem={true}
                      twoStepAuth={false}
                      redemtionText="Descargar"
                      termText="Descripción breve. TyC"
                      termLink="/terminos-y-condiciones"
                      isRouletteRedemtion={true}
                      closeInitModal={true}
                    />

                    <p>Guardamos tu bono en nuestro <strong>Catálogo de premios</strong>. ¡Úsalo cuando quieras!</p>

                    <Button
                      type="secondary"
                      text="Aceptar"
                      loading={false}
                      status="active"
                      onClick={closeRoulette}
                    />

                  </div>
              }
              
              {ruletteStep < 3 && 
                <p>Solo podrás girar de nuevo cuando completes tu siguiente meta y superes tu nuevo reto.</p>
              }

            </VerticalCenter>
          : <VerticalCenter>
              {steps.map((step, index) => {
                return (
                  <div className={`mc-fullscreen-modal__step step-${index+1} ${modalStep == index+1 ? 'step-active' : ''}`} key={index}>
                    <div className="mc-fullscreen-modal__center--title">
                      <div dangerouslySetInnerHTML={{ __html: level1Winner ? step.winnerTitleLevel1 : step.title }} />
                      {level1Winner &&
                        <div dangerouslySetInnerHTML={{ __html: step.winnerSubtitleLevel1 }} />
                      }
                    </div>
                    <p>
                      <div dangerouslySetInnerHTML={{ __html: step.text }} />
                    </p>

                    {context
                      ? <>
                          {modalStep === 1
                            ? <div className='mc-fullscreen-modal__center--banner'>
                                <div>Tu { context.block === 1 ? 'primera' : 'segunda'} meta es de:</div>
                                <div>{context ? getPrice(context.user[`goal_${context.block}_level_1`]) : ''}</div>
                                <div>En compras con tus Tarjetas 
                                  {context.user.segment === '0credito'
                                    ? ' de Crédito'
                                    : context.user.segment === 'debito0'
                                      ? 'de Débito'
                                      : ' de Crédito y Débito'
                                  } Mastercard AV Villas
                                </div>
                                <div>
                                  Cúmplela y llévate un bono de <strong>{context ? getPrice(context.user[`award_${context.block}_level_1`]) : ''}</strong>
                                </div>
                              </div>
                            : 
                            
                           modalStep === 2
                           ? 
                           <div className={`mc-fullscreen-modal__center--table ${getLevel(context) === 2 ? 'level-2' : 'level-1'}`}>
                           <p>Tu nivel actual es:</p>

                           {context &&
                             <Levels
                               levels={
                                 [
                                   {
                                     image: 'level-1.png',
                                     title: getLevelName(1),
                                     subtitle: 'Meta',
                                     amount: getPrice(context.user[`goal_${context.block}_level_1`]),
                                     award: getPrice(context.user[`award_${context.block}_level_1`]),
                                     active: getLevel(context) === 1 ? true : false
                                   },
                                   {
                                     image: 'level-2.png',
                                     title: getLevelName(2),
                                     subtitle: 'Meta',
                                     amount: getPrice(context.user[`goal_${context.block}_level_2`]),
                                     award: getPrice(context.user[`award_${context.block}_level_2`]),
                                     active: getLevel(context) === 2 ? true : false
                                   }
                                 ]
                               }
                               type={'table'}
                               level={getLevel(context)}
                             />
                           }
                         </div>
                           :

                           <>
                           <div className='roulette-segment'>
                            <img src={`/assets/images/ruleta-${context.user[`award_extra_${context.block}`]}.png`} alt="" />
                           </div>
                           <p>
                            {step.text3}
                           </p>
                           </>
                          }
                        </>
                      : <Preloader
                          type="section"
                          invert
                        />
                    }



                    {!level1Winner &&
                      <div className='mc-fullscreen-modal__center--dots'>
                        <span onClick={goToOne} className={` ${modalStep == 1 ? 'dot-active' : ''}`}></span>
                        <span onClick={ goToTwo} className={`${modalStep == 2 ? 'dot-active' : ''}`}></span>
                        <span onClick={ goToThree} className={`${modalStep == 3 ? 'dot-active' : ''}`}></span>
                      </div>
                    }
                    
                    {(modalStep === 2 && !level1Winner) &&
                      <Button
                        type="primary"
                        loading={false}
                        status="active"
                        isNext={true}
                        onClick={goToOne}
                        goBack={true}
                      />
                    }
                    {!level1Winner &&
                      <Button
                        type="primary"
                        text={`${(modalStep == 1 || modalStep == 2)? 'Seguir' : 'Finalizar y seguir'}`}
                        loading={false}
                        status="active"
                        isNext={true}
                        onClick={modalStep == 1 ? goToTwo : modalStep == 2 ? goToThree : goToFinish}
                        goBack={false}
                      />
                    }
                    {!level1Winner &&
                      <button className='mc-fullscreen-modal__center--skip' onClick={goToFinish}>Saltar</button>
                    }

                    {level1Winner &&
                      <Button
                        type="secondary"
                        text={`Redimir bono`}
                        loading={false}
                        status="active"
                        onClick={goToAwards}
                        className="push-awards"
                      />
                    }
                    {level1Winner && getLevel(context) === 1 && 
                      <Button
                        type="primary"
                        text={`Aceptar nueva meta`}
                        loading={loadingNewLevel}
                        status="active"
                        onClick={goToLevel}
                        className="push-primary"
                      />
                    }
                  </div>
                )
              })}
            </VerticalCenter>
        }
        
      </div>
    </div>
  )
}

export default FullScreenModal