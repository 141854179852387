import React from 'react'

const BackButton = () => {

    const handleClick = () => {
        window.history.back();
    }

    return (
        <div className="back-button" onClick={handleClick}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M6.00016 9.33333L3.3335 6.66667L6.00016 4" stroke="#0048DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.3335 6.66699H10.6668C11.3741 6.66699 12.0524 6.94794 12.5524 7.44804C13.0525 7.94814 13.3335 8.62641 13.3335 9.33366C13.3335 10.0409 13.0525 10.7192 12.5524 11.2193C12.0524 11.7194 11.3741 12.0003 10.6668 12.0003H10.0002" stroke="#0048DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div>
                <span>Regresar</span>
            </div>
        </div>
    )
}

export default BackButton