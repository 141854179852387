import React, { useEffect, useContext } from 'react'

import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import Preloader from '../atoms/Preloader'
import FaqsFeed from '../organisms/FaqsFeed'
import BackButton from '../atoms/BackButton'

function Faqs() {
  const { staticContent } = useContext(StateController)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page faqs slow_ani'>

      <Container className="mc-page-container">

        <BackButton />

        <p className="head-terms-title">Encuentra todas las respuestas que necesitas aquí. <strong>Participa, cumple tu reto de compras y gana</strong> con tus Tarjetas AV Villas Mastercard.</p>
        
        <div className="head-terms-box">
          <h1>Preguntas frecuentes campaña “Conecta tus compras”</h1>
          <p>Banco AV Villas</p>
          <a href="https://ganacontustarjetasmastercard.bancoavvillas.com.co">https://ganacontustarjetasmastercard.bancoavvillas.com.co</a>
        </div>

        {staticContent
          ? <FaqsFeed
              faqsType={1}
              list={staticContent.faqs}
            />
          : <Preloader
              type="section"
              invert
            />
        }
      </Container>
      
    </div>
  )
}
export default Faqs