import React from 'react'

const PageHeaders = ({
  title,
  text,
  className
}) => {

  return (
    <div className={`mc-pre-page ${className ? className : ''}`}>
      <div dangerouslySetInnerHTML={{ __html: title }} />
      <p>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </p>
    </div>
  )
}

export default PageHeaders