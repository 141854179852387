import React from 'react'
import Columns, { Col } from "../../layouts/Columns/Columns";
import Container from "../../layouts/Container/Container";
import Button from "../atoms/Button";
function NotFound() {

  return (
    <section className='mc-page not-found slow_ani'>
      <article>
        <Container>
          <Columns>
            <Col>
              <div className='img-not-found'><img src="/assets/images/error-404.png" alt="Error 404" /></div>
            </Col>
            <Col>
              <div>
                <h1>Error 404</h1>
                <p className='text-not-found'>Lo sentimos, pero no logramos encontrar lo que estás buscando.</p>
                <div className='btn-404'>
                  <Button
                    type='primary'
                    text='Ir a Progreso'
                    status='active'
                    link={'/'}
                  />
                </div>
              </div>
            </Col>
          </Columns>
        </Container>
      </article>
    </section>
  )
}
export default NotFound